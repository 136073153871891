import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';

function Ofertas() {
  const [ofertasData, setOfertasData] = useState([]);
  const [currentLargeImage, setCurrentLargeImage] = useState(0);

  useEffect(() => {
    // Fetch de los productos en oferta desde el archivo PHP
    fetch('https://titoscoltauco.cl/fetch_products.php?category=ofertas')
      .then((response) => response.json())
      .then((data) => {
        setOfertasData(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLargeImage((prevImage) => (prevImage + 1) % ofertasData.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [ofertasData.length]);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
        Ofertas Exclusivas
      </Typography>
      {ofertasData.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                position: 'relative',
                height: '400px',
                backgroundImage: `url(${ofertasData[currentLargeImage].imagen})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              <Card
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  color: '#ffffff',
                  padding: 2,
                  borderRadius: 2,
                }}
              >
                <CardContent>
                  <Typography variant="h5">{ofertasData[currentLargeImage].nombre}</Typography>
                  <Typography variant="body2">Marca: {ofertasData[currentLargeImage].marca}</Typography>
                  <Typography variant="body2" sx={{ textDecoration: 'line-through', color: 'red' }}>
                    Precio Original: {new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(ofertasData[currentLargeImage].precio_original)}
                  </Typography>
                  <Typography variant="h6" sx={{ color: '#00e676' }}>
                    Precio Oferta: {new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(ofertasData[currentLargeImage].precio_oferta)}
                  </Typography>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      backgroundColor: 'red',
                      color: 'white',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      fontSize: '0.8em',
                      fontWeight: 'bold',
                    }}
                  >
                    Oferta
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              {ofertasData
                .filter((_, index) => index !== currentLargeImage)
                .map((oferta) => (
                  <Grid item xs={6} key={oferta.id}>
                    <Box
                      sx={{
                        position: 'relative',
                        height: '190px',
                        backgroundImage: `url(${oferta.imagen})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: 2,
                        boxShadow: 3,
                      }}
                    >
                      <Card
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.6)',
                          color: '#ffffff',
                          padding: 1,
                          borderRadius: 2,
                        }}
                      >
                        <CardContent sx={{ padding: 1 }}>
                          <Typography variant="body1">{oferta.nombre}</Typography>
                          <Typography variant="body2">Marca: {oferta.marca}</Typography>
                          <Typography variant="body2" sx={{ textDecoration: 'line-through', color: 'red' }}>
                            {new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(oferta.precio_original)}
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#00e676' }}>
                            {new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(oferta.precio_oferta)}
                          </Typography>
                        </CardContent>
                      </Card>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 10,
                          right: 10,
                          backgroundColor: 'red',
                          color: 'white',
                          padding: '5px 10px',
                          borderRadius: '5px',
                          fontSize: '0.8em',
                          fontWeight: 'bold',
                        }}
                      >
                        Oferta
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default Ofertas;
