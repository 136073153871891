import React from 'react';
import { AppBar, Toolbar, IconButton, Link, Box, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import './TopBar.css';  // Importa el CSS externo

function TopBar() {
  return (
    <AppBar position="static" className="topbar-appbar">
      <Toolbar className="topbar-toolbar">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton className="topbar-icon-button" component={Link} href="https://github.com/FrancisAlonso/Portfolio">
            <FacebookIcon fontSize="small" />
          </IconButton>
          <IconButton className="topbar-icon-button" component={Link} href="https://github.com/FrancisAlonso/Portfolio">
            <WhatsAppIcon fontSize="small" />
          </IconButton>
          <IconButton className="topbar-icon-button" component={Link} href="https://github.com/FrancisAlonso/Portfolio">
            <InstagramIcon fontSize="small" />
          </IconButton>
          <IconButton className="topbar-icon-button" component={Link} href="https://github.com/FrancisAlonso/Portfolio">
            <PlayCircleOutlineIcon fontSize="small" />
          </IconButton>
        </Box>
        <Typography variant="body2" className="topbar-typography">
          COMPRA HOY Y RECIBE TU PEDIDO EN LA SEMANA - ENVIOS A TODO CHILE
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
