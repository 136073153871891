import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, Grid, Card, CardContent, Collapse, IconButton} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import './FilterSidebar.css';

function FilterSidebar({ category, onFilterChange }) {
  const [filters, setFilters] = useState({
    tipo: [],
    marca: [],
  });

  const [openSections, setOpenSections] = useState({
    tipo: false,
    marca: false,
  });



  useEffect(() => {
    fetch(`https://titoscoltauco.cl/fetch_filter_options.php?category=${category}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error en la respuesta de la API: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => setFilters(data))
      .catch((error) => {
        console.error("Error fetching filter options:", error);
        setFilters({
          tipo: [],
          marca: [],
        });
      });
  }, [category]);

  const handleFilterChange = (filterType, value) => {
    onFilterChange(filterType, value);
  };

  const toggleSection = (section) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [section]: !prevOpenSections[section],
    }));
  };


  return (
    <Card className="filter-card">
      <CardContent>
        <Typography variant="h5" className="filter-title">
          Filtrar Por
        </Typography>

        <Box className="filter-section">
          <Box className="filter-header" onClick={() => toggleSection('tipo')}>
            <Typography variant="subtitle1" className="filter-subtitle">
              Tipo
            </Typography>
            <IconButton size="small">
              {openSections.tipo ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={openSections.tipo} className="filter-collapse">
            <Grid container spacing={0.5}>
              {filters.tipo.length > 0 ? (
                filters.tipo.map((tipo) => (
                  <Grid item xs={6} key={tipo}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          onChange={() => handleFilterChange('tipo', tipo)}
                        />
                      }
                      label={<Typography variant="body2" className="filter-option">{tipo}</Typography>}
                    />
                  </Grid>
                ))
              ) : (
                <Typography variant="body2" className="no-options">No hay opciones</Typography>
              )}
            </Grid>
          </Collapse>
        </Box>

        <Box className="filter-section">
          <Box className="filter-header" onClick={() => toggleSection('marca')}>
            <Typography variant="subtitle1" className="filter-subtitle">
              Marca
            </Typography>
            <IconButton size="small">
              {openSections.marca ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={openSections.marca} className="filter-collapse">
            <Grid container spacing={0.5}>
              {filters.marca.length > 0 ? (
                filters.marca.map((marca) => (
                  <Grid item xs={6} key={marca}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          onChange={() => handleFilterChange('marca', marca)}
                        />
                      }
                      label={<Typography variant="body2" className="filter-option">{marca}</Typography>}
                    />
                  </Grid>
                ))
              ) : (
                <Typography variant="body2" className="no-options">No hay opciones</Typography>
              )}
            </Grid>
          </Collapse>
        </Box>
      </CardContent>
    </Card>
  );
}

export default FilterSidebar;
