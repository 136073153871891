import React, { useState, useEffect } from 'react';
import { Box, InputBase, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

function SearchBar({ isFixed }) {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    if (query) {
      fetch(`https://titoscoltauco.cl/search_products.php?query=${query}`)
        .then((response) => response.json())
        .then((data) => {
          setSuggestions(data);
          setShowSuggestions(true);
        })
        .catch((error) => console.error('Error fetching search suggestions:', error));
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [query]);

  const handleClear = () => {
    setQuery('');
    setShowSuggestions(false);
  };

  return (
    <Box sx={{ position: 'relative', width: isFixed ? '200px' : '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#ffffff', borderRadius: '4px' }}>
        <InputBase
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar productos..."
          sx={{
            width: '100%',
            padding: '10px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            outline: 'none',
            color: '#000000',
          }}
        />
        <IconButton onClick={handleClear} sx={{ color: '#000', padding: '10px' }}>
          <CloseIcon />
        </IconButton>
      </Box>
      {showSuggestions && suggestions.length > 0 && (
        <List
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            maxHeight: '200px',
            overflowY: 'auto',
            background: 'linear-gradient(-45deg, #2E3033, #3273CF, #3A434F, #465C7A)',
            backgroundSize: '400% 400%',
            animation: 'gradientAnimation 15s ease infinite',
            boxShadow: 3,
            zIndex: 1300,
            borderRadius: '4px',
            color: '#ffffff',
          }}
        >
          {suggestions.slice(0, 5).map((suggestion, index) => (
            <ListItem
              button
              key={index}
              component="a"
              href={`/tienda/${suggestion.categoria}`}
              sx={{
                '&:hover': {
                  backgroundColor: '#465C7A',
                  color: '#ffffff',
                },
                borderBottom: '1px solid #E3DAC9',
              }}
            >
              <ListItemText primary={suggestion.nombre} sx={{ color: '#ffffff' }} />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}

// Añade las animaciones globales al final del archivo
const styles = document.createElement('style');
styles.type = 'text/css';
styles.innerHTML = `
  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;
document.head.appendChild(styles);

export default SearchBar;
