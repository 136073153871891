import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import portada1 from '../img/portada1.jpg';
import portada2 from '../img/portada2.jpg';
import portada3 from '../img/portada3.jpg';
import portada4 from '../img/portada4.jpg';
import './CoverImage.css';

function CoverImage() {
  const images = [portada1, portada2, portada3, portada4];
  const titles = [
    'Bienvenido a TITOS COLTAUCO',
    'Ofertas de la semana',
    'Nuevos productos',
    'Descubre nuestras promociones',
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleNavigation = (category) => {
    navigate(`/tienda/${category}`);
  };

  return (
    <Box className="cover-image-container">
      {/* Portada principal */}
      <Box className="cover-image-main">
        <img
          src={images[currentImageIndex]}
          alt="Portada"
        />
        <Typography variant="h3" className="cover-image-title">
          {titles[currentImageIndex]}
        </Typography>
      </Box>

      {/* Imágenes pequeñas en vertical */}
      <Box className="cover-image-small-container">
        {[
          { src: portada2, alt: 'Abarrotes', category: 'pantalones' },
          { src: portada3, alt: 'Confites', category: 'chaquetas' },
          { src: portada4, alt: 'Bebidas', category: 'poleras' },
          { src: portada2, alt: 'Utiles de Aseo', category: 'zapatos' },
          { src: portada3, alt: 'Congelados', category: 'accesorios' },
          { src: portada4, alt: 'y Mas', category: 'ropa-interior' },
        ].map((image, index) => (
          <Box
            key={index}
            className="cover-image-small-box"
            onClick={() => handleNavigation(image.category)}
          >
            <img
              src={image.src}
              alt={image.alt}
            />
            <Typography variant="h5" className="cover-image-small-title">
              {image.alt}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default CoverImage;
