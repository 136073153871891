import React from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';
import teamImage1 from '../img/team1.jpg';
import teamImage2 from '../img/team2.jpg';
import missionImage from '../img/mission.jpg';
import './AboutUs.css'; // Importar el archivo CSS

function AboutUs() {
  return (
    <Box className="about-us-container">
      <Typography variant="h3" className="about-us-title">
        Conoce Nuestra Historia
      </Typography>

      <Typography variant="body1" className="about-us-description">
        En Tito's Coltauco, somos una tienda familiar comprometida con ofrecer una amplia variedad de abarrotes, golosinas y productos de aseo. Fundada y dirigida por Miguel Ramírez y su pareja, nuestra tienda es un reflejo de nuestro amor por la comunidad y el compromiso con la calidad. Desde nuestros inicios, hemos trabajado arduamente para ser un pilar en la comunidad, brindando productos esenciales para cada hogar.
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card className="mission-card">
            <CardContent>
              <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#3273CF' }}>
                Nuestra Misión
              </Typography>
              <Typography variant="body1">
                Nuestra misión es proveer a nuestros clientes con productos de la más alta calidad, a precios accesibles, asegurando que todos los hogares tengan lo que necesitan para el día a día. Valoramos la honestidad, la dedicación y el servicio al cliente excepcional, y trabajamos para hacer de Tito's Coltauco un lugar donde cada cliente se sienta como en casa.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="mission-image-container">
            <img
              src={missionImage}
              alt="Nuestra Misión"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{ mt: 6, mb: 2, textAlign: 'center', fontWeight: 'bold' }}>
        Conoce a Nuestro Equipo
      </Typography>

      <Typography variant="body1" className="about-us-description">
        Tito's Coltauco no sería lo que es hoy sin el esfuerzo y dedicación de nuestro equipo. Miguel Ramírez y su pareja lideran con pasión y determinación, asegurando que cada cliente reciba el mejor servicio y los mejores productos.
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card className="team-card">
            <CardContent>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#3273CF' }}>
                Miguel Ramírez, Fundador
              </Typography>
              <Typography variant="body1">
                Miguel es el corazón de Tito's Coltauco. Con su visión clara y su dedicación, ha convertido a la tienda en un referente en la comunidad, siempre buscando formas de mejorar y ofrecer lo mejor a sus clientes.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="team-image-container">
            <img
              src={teamImage1}
              alt="Miguel Ramírez"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="team-image-container">
            <img
              src={teamImage2}
              alt="Equipo"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className="team-card">
            <CardContent>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#3273CF' }}>
                Equipo de Ventas
              </Typography>
              <Typography variant="body1">
                Nuestro equipo de ventas, liderado por la pareja de Miguel, es el rostro amable que recibe a nuestros clientes día a día. Con su calidez y atención al detalle, hacen de cada visita una experiencia especial.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AboutUs;
