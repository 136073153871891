import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Box, Card, CardMedia, CardContent, Typography, Button, Grid } from '@mui/material';
import { useParams } from 'react-router-dom'; // Importar useParams para obtener la categoría de la URL
import FilterSidebar from './FilterSidebar';
import { CarritoContext } from '../context/CarritoContext';
import './Tienda.css';

function Tienda() {
  const { category } = useParams(); // Obtener la categoría de la URL
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    tipo: [],
    marca: [],
  });

  const { agregarAlCarrito } = useContext(CarritoContext);

  useEffect(() => {
    fetch(`https://titoscoltauco.cl/fetch_products.php?category=${category}`)
      .then((response) => response.json())
      .then((data) => {
        setItems(data);
        setFilteredItems(data); // Iniciar con todos los productos filtrados
      })
      .catch((error) => console.error(`Error al cargar los datos de ${category}:`, error));
  }, [category]);

  const filterItems = useCallback(() => {
    let filtered = items;

    Object.keys(activeFilters).forEach((key) => {
      if (activeFilters[key].length > 0) {
        filtered = filtered.filter((item) =>
          activeFilters[key].includes(item[key]?.toLowerCase())
        );
      }
    });

    setFilteredItems(filtered);
  }, [items, activeFilters]);

  useEffect(() => {
    filterItems();
  }, [activeFilters, items, filterItems]);

  const handleFilterChange = (filterType, value) => {
    setActiveFilters((prevFilters) => {
      const currentFilters = prevFilters[filterType];
      const newFilters = currentFilters.includes(value.toLowerCase())
        ? currentFilters.filter((v) => v !== value.toLowerCase())
        : [...currentFilters, value.toLowerCase()];
      return {
        ...prevFilters,
        [filterType]: newFilters,
      };
    });
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  return (
    <Box className="tienda-container">
      <Box className="tienda-sidebar">
        <FilterSidebar category={category} onFilterChange={handleFilterChange} />
      </Box>
      <Box className="tienda-content">
        <Grid container spacing={2}>
          {filteredItems.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.id}>
              <Card className="tienda-card">
                <CardMedia
                  component="img"
                  src={item.imagen}
                  alt={item.nombre}
                  className="tienda-media"
                />
                <CardContent className="cards">
                  <Typography variant="h6" component="div">
                    {item.nombre}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Tipo: {item.tipo}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Marca: {item.marca}
                  </Typography>
                  <Typography variant="body2" className="tienda-price-strikethrough">
                    {formatPrice(item.precio_original)}
                  </Typography>
                  <Typography variant="h6" color="primary" className="tienda-price">
                    {formatPrice(item.precio_oferta)}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    className="tienda-button"
                    onClick={() => agregarAlCarrito(item)}
                  >
                    Añadir al carrito
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default Tienda;
