import React, { useState, useEffect, useContext } from 'react';
import { Box, Card, CardMedia, CardContent, Typography, Button, Chip } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './NewArrivalsCarousel.css';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { CarritoContext } from '../context/CarritoContext';

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick}>
      <ArrowBackIos />
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick}>
      <ArrowForwardIos />
    </div>
  );
};

function NewArrivalsCarousel() {
  const [items, setItems] = useState([]);
  const { agregarAlCarrito } = useContext(CarritoContext);

  useEffect(() => {
    fetch('https://titoscoltauco.cl/fetch_new_arrivals.php')
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.slice(0, 8);
        setItems(filteredData);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const formatPrice = (price) => {
    if (typeof price !== 'number' || isNaN(price)) {
      return "Precio no disponible";
    }
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const handleAddToCart = (item) => {
    const productToAdd = {
      ...item,
      precio_original: item.precio_original || item.precio,  // Asegura que el precio original esté presente
      precio_oferta: item.precio,  // Usa el precio en oferta como el precio principal
    };
    agregarAlCarrito(productToAdd);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1800,
    nextArrow: <ArrowForwardIos />,
    prevArrow: <ArrowBackIos />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box className="new-arrivals-carousel-container">
      <Typography variant="h4" className="new-arrivals-carousel-title">
        Lo más reciente
      </Typography>
      <Slider {...settings}>
        {items.map((item) => (
          <Box key={item.id} className="new-arrivals-carousel-item">
            <Card className="new-arrivals-carousel-card">
              <CardMedia
                component="img"
                height="200"
                image={item.imagen}
                alt={item.nombre}
                className="new-arrivals-carousel-media"
              />
              <Chip label="Nuevo" className="new-arrival-chip" />
              <CardContent className="new-arrivals-carousel-card-content">
                <Typography variant="h6" component="div">
                  {item.nombre}
                </Typography>
                <Typography variant="body2">
                  Marca: {item.marca}
                </Typography>
                <Typography variant="body2">
                  Llegada: {item.fecha_llegada}
                </Typography>
                <Typography variant="h6" color="primary" className="new-arrivals-carousel-price">
                  {formatPrice(parseFloat(item.precio))}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className="new-arrivals-carousel-button"
                  onClick={() => handleAddToCart(item)}
                >
                  Añadir al carrito
                </Button>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}

export default NewArrivalsCarousel;
