import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import SearchBar from './SearchBar';
import CartIcon from './CartIcon';
import logo from '../img/logo3.png';
import './BottomBar.css';

function BottomBar() {
  const [isFixed, setIsFixed] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const bottomBar = document.getElementById('bottom-bar');
      const bottomBarTop = bottomBar.offsetTop;

      if (offset > bottomBarTop) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
    if (!open) setSubMenuOpen(false);
  };

  const handleSubMenuToggle = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleSubMenuClose = () => {
    setSubMenuOpen(false);
  };

  const mainMenuItems = [
    { text: 'Home', link: '/' },
    { text: 'Nosotros', link: '/about' },
    { text: 'Tienda', action: handleSubMenuToggle },
    { text: 'Contacto', link: '/contacto' },
    { text: 'Ofertas', link: '/ofertas' },
  ];

  // Actualizar las categorías del submenú
  const subMenuItems = [
    { text: 'Abarrotes', link: '/tienda/abarrotes' },
    { text: 'Confites', link: '/tienda/confites' },
    { text: 'Bebidas', link: '/tienda/bebidas' },
    { text: 'Útiles de Aseo', link: '/tienda/utiles-de-aseo' },
    { text: 'Congelados', link: '/tienda/congelados' },
  ];

  const getButtonClass = (item) => {
    if (item.link && location.pathname === item.link) {
      return 'bottom-bar-menu-item tienda-button';
    }
    if (item.action && location.pathname.startsWith('/tienda')) {
      return 'bottom-bar-menu-item tienda-button';
    }
    return 'bottom-bar-menu-item';
  };

  return (
    <AppBar id="bottom-bar" className={isFixed ? 'fixed' : 'static'}>
      <Toolbar className="bottom-bar-toolbar">
        {isFixed && (
          <Box component="img" src={logo} alt="Logo" className="bottom-bar-logo" />
        )}

        <Box className="bottom-bar-menu">
          {mainMenuItems.map((item, index) =>
            item.action ? (
              <Box
                key={index}
                className={`bottom-bar-submenu ${subMenuOpen ? 'show' : ''}`}
              >
                <Typography
                  variant="body2"
                  className={getButtonClass(item)}
                  onClick={item.action}
                >
                  {item.text}
                </Typography>
                <Box
                  className={`bottom-bar-submenu-content ${
                    subMenuOpen ? 'show' : ''
                  }`}
                >
                  {subMenuItems.map((subItem, index) => (
                    <Typography
                      key={index}
                      variant="body2"
                      component="a"
                      href={subItem.link}
                      className="bottom-bar-submenu-item"
                      onClick={handleSubMenuClose}
                    >
                      {subItem.text}
                    </Typography>
                  ))}
                </Box>
              </Box>
            ) : (
              <Typography
                key={index}
                variant="body2"
                component="a"
                href={item.link}
                className={getButtonClass(item)}
              >
                {item.text}
              </Typography>
            )
          )}
        </Box>

        <IconButton
          onClick={toggleDrawer(true)}
          className="bottom-bar-icon-button"
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box className="drawer-content" role="presentation">
            <IconButton onClick={toggleDrawer(false)} sx={{ margin: 1 }}>
              <CloseIcon />
            </IconButton>
            <Divider />
            <List>
              {!subMenuOpen ? (
                mainMenuItems.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={item.action || toggleDrawer(false)}
                    component="a"
                    href={item.link}
                    className="drawer-list-item"
                  >
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))
              ) : (
                <>
                  <ListItem button onClick={handleSubMenuClose}>
                    <ListItemText primary="Volver al menú principal" />
                  </ListItem>
                  <Divider />
                  {subMenuItems.map((item, index) => (
                    <ListItem
                      button
                      key={index}
                      component="a"
                      href={item.link}
                      onClick={toggleDrawer(false)}
                    >
                      <ListItemText primary={item.text} />
                    </ListItem>
                  ))}
                </>
              )}
            </List>
          </Box>
        </Drawer>

        {isFixed && (
          <>
            <CartIcon />
            <SearchBar isFixed={isFixed} />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default BottomBar;
