import React, { useState, useEffect, useContext } from 'react';
import { Box, Card, CardMedia, CardContent, Typography, Button, Chip } from '@mui/material';
import Slider from 'react-slick';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { CarritoContext } from '../context/CarritoContext';
import './ProductCarousel.css';

function ProductCarousel() {
  const [items, setItems] = useState([]);
  const { agregarAlCarrito } = useContext(CarritoContext);

  useEffect(() => {
    fetch('https://titoscoltauco.cl/fetch_products_by_category.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (!Array.isArray(data)) {
          throw new Error('Received data is not an array');
        }
        // Filtrar solo los productos que están en promoción
        const promotionalItems = data.filter(item => parseFloat(item.precio_oferta) < parseFloat(item.precio_original));
        setItems(promotionalItems);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const calculateDiscount = (original, offer) => {
    return Math.round(((original - offer) / original) * 100);
  };

  const handleAddToCart = (item) => {
    agregarAlCarrito(item);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1800,
    nextArrow: <ArrowForwardIos />,
    prevArrow: <ArrowBackIos />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box className="product-carousel-container">
      <Typography variant="h4" className="product-carousel-title">
        Productos en oferta
      </Typography>
      <Slider {...settings}>
        {items.map((item) => (
          <Box key={item.id} className="product-carousel-item">
            <Card className="product-carousel-card">
              <CardMedia
                component="img"
                height="200"
                image={item.imagen}
                alt={item.nombre}
                className="product-carousel-media"
              />
              <Chip
                label={`-${calculateDiscount(item.precio_original, item.precio_oferta)}%`}
                className="discount-chip"
              />
              <CardContent className="product-carousel-card-content">
                <Typography variant="h6" component="div">
                  {item.nombre}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Marca: {item.marca}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Tipo: {item.tipo}
                </Typography>
                <Typography variant="body2" className="product-carousel-price-strikethrough">
                  {formatPrice(item.precio_original)}
                </Typography>
                <Typography variant="h6" color="primary" className="product-carousel-price">
                  {formatPrice(item.precio_oferta)}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className="product-carousel-button"
                  onClick={() => handleAddToCart(item)}
                >
                  Añadir al carrito
                </Button>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}

export default ProductCarousel;
