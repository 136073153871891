import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import aboutUsImage from '../img/aboutUsBrief.jpg'; // Imagen breve de "Nosotros"

function AboutUsBrief() {
  return (
    <Box sx={{ padding: 4 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <img
            src={aboutUsImage}
            alt="Nosotros"
            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
            Sobre Nosotros
          </Typography>
          <Typography variant="body1">
            En Gusito Store, somos más que una tienda; somos una familia dedicada a ofrecer productos de calidad. Nos apasiona la moda y queremos compartir esa pasión contigo.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AboutUsBrief;
