import React from 'react';
import { Box, Typography, Link, Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import logo from '../img/logo1.jpeg'; // Importa el logo de Tito's Coltauco
import './Footer.css'; // Importa el archivo CSS

function Footer() {
  return (
    <Box className="footer-container">
      <Grid container spacing={4}>
        {/* ACERCA DE TITO'S COLTAUCO */}
        <Grid item xs={12} md={5}>
          <Box className="footer-about">
            <Box
              component="img"
              src={logo}
              alt="Tito's Coltauco Logo"
              className="footer-logo"
            />
            <Box>
              <Typography variant="h6" className="footer-title">
                ACERCA DE TITO'S COLTAUCO
              </Typography>
              <Typography variant="body2" className="footer-description">
                Tito's Coltauco es una tienda familiar dirigida por Miguel Ángel Ramírez Galaz y su pareja. Ofrecemos una amplia variedad de abarrotes, golosinas, y productos de aseo, comprometidos siempre con la comunidad y la calidad de nuestros productos.
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Envíos y Medios de Pago */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" className="footer-title">
            Envíos y Medios de Pago
          </Typography>
          <Box className="footer-info">
            <LocationOnIcon className="footer-icon" />
            <Typography variant="body2" className="footer-text">
              Av. La Paz S/N, Lote 2 Lo Ulloa, Coltauco, Ruta H-362
            </Typography>
          </Box>
          <Box className="footer-info">
            <PhoneIcon className="footer-icon" />
            <Typography variant="body2" className="footer-text">
              +569 4875 2065
            </Typography>
          </Box>
          <Box className="footer-info">
            <EmailIcon className="footer-icon" />
            <Typography variant="body2" className="footer-text">
              miguel.ramirez@titoscoltauco.cl
            </Typography>
          </Box>
          <Box className="footer-info">
            <AccessTimeIcon className="footer-icon" />
            <Typography variant="body2" className="footer-text">
              Lun - Vie / 9:00 - 18:00 Hrs.
            </Typography>
          </Box>
          <Box className="footer-social">
            <Link href="#" className="footer-link"><FacebookIcon /></Link>
            <Link href="#" className="footer-link"><YouTubeIcon /></Link>
            <Link href="#" className="footer-link"><InstagramIcon /></Link>
            <Link href="#" className="footer-link"><MusicNoteIcon /></Link> {/* Representa TikTok */}
          </Box>
        </Grid>

        {/* Categorías y Páginas */}
        <Grid item xs={12} md={3}>
          <Box className="footer-categories">
            <Typography variant="h6" className="footer-title">
              Categorías
            </Typography>
            <Typography variant="body2" className="footer-text">
              <Link href="/tienda/abarrotes" className="footer-link">Abarrotes</Link>
              <br />
              <Link href="/tienda/confites" className="footer-link">Golosinas</Link>
              <br />
              <Link href="/tienda/aseo" className="footer-link">Productos de Aseo</Link>
            </Typography>
          </Box>
          <Box className="footer-pages">
            <Typography variant="h6" className="footer-title">
              Páginas
            </Typography>
            <Typography variant="body2" className="footer-text">
              <Link href="/about" className="footer-link">Nosotros</Link>
              <br />
              <Link href="/tienda" className="footer-link">Tienda</Link>
              <br />
              <Link href="/contacto" className="footer-link">Contacto</Link>
              <br />
              <Link href="/ofertas" className="footer-link">Ofertas</Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
