import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from './components/TopBar';
import MiddleBar from './components/MiddleBar';
import BottomBar from './components/BottomBar';
import CoverImage from './components/CoverImage';
import NewArrivalsCarousel from './components/NewArrivalsCarousel';
import AboutUsBrief from './components/AboutUsBrief';
import Carrito from './components/Carrito';
import Ofertas from './components/Ofertas';
import AboutUs from './components/AboutUs';
import Contacto from './components/Contacto';
import Footer from './components/Footer';
import ProductCarousel from './components/ProductCarousel';
import Tienda from './components/Tienda'; // Importa el componente Tienda
import { CarritoProvider } from './context/CarritoContext';

function App() {
  return (
    <Router>
      <CarritoProvider>
        <TopBar />
        <MiddleBar />
        <BottomBar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <CoverImage />
                <NewArrivalsCarousel />
                <ProductCarousel />
                <AboutUsBrief />
              </>
            }
          />
          <Route path="/carrito" element={<Carrito />} />
          <Route path="/ofertas" element={<Ofertas />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/tienda/:category" element={<Tienda />} /> {/* Nueva ruta para Tienda */}
        </Routes>
        <Footer />
      </CarritoProvider>
    </Router>
  );
}

export default App;
