import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import SearchBar from './SearchBar';
import CartIcon from './CartIcon';
import logo from '../img/logo1.jpeg';
import './MiddleBar.css';  // Importa el CSS externo

function MiddleBar() {
  return (
    <AppBar position="static" className="middlebar-appbar">
      <Toolbar className="middlebar-toolbar">
        
        {/* Logo */}
        <Box
          component={Link}
          to="/"
          className="middlebar-logo"
        >
          <Box className="middlebar-logo1"
            component="img"
            src={logo}
            alt="Logo"
          />
        </Box>

        {/* Barra de búsqueda */}
        <Box className="middlebar-searchbar">
          <SearchBar />
        </Box>

        {/* Icono de carrito */}
        <CartIcon />

        {/* Teléfono y seguimiento */}
        <Box className="middlebar-phone">
          <PhoneIcon fontSize="small" />
          <Box className="middlebar-phone-details">
            <Box component="span" className="middlebar-phone-number">
              +56954018875
            </Box>
            <Box component="span" className="middlebar-phone-tracking">
              Seguimiento
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default MiddleBar;
