import React, { useContext } from 'react';
import { Box, Typography, Button, List, ListItem, ListItemText, Divider, Grid, IconButton, TextField, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { CarritoContext } from '../context/CarritoContext';
import DeleteIcon from '@mui/icons-material/Delete';

const Carrito = () => {
  const { carrito, eliminarDelCarrito } = useContext(CarritoContext);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);
  };

  const calcularTotal = () => {
    return carrito.reduce((acc, item) => acc + (parseFloat(item.precio_oferta) * (item.quantity || 1)), 0);
  };

  const calcularAhorro = () => {
    return carrito.reduce((acc, item) => acc + ((parseFloat(item.precio_original) - parseFloat(item.precio_oferta)) * (item.quantity || 1)), 0);
  };

  return (
    <Box
      sx={{
        padding: 4,
        background: 'linear-gradient(135deg, #f0f4f8, #d9e6f2)',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        gap: 4,
      }}
    >
      {/* Sección Izquierda: Elementos del Carrito */}
      <Box sx={{ flex: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: 2, padding: 2 }}>
        <Typography
          variant="h4"
          sx={{ mb: 4, textAlign: 'center', fontWeight: 'bold', color: '#2E3033' }}
        >
          Tu Carrito de Compras
        </Typography>
        <List>
          {carrito.map((item) => (
            <React.Fragment key={item.id}>
              <ListItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={3}>
                    <img src={item.imagen} alt={item.nombre} style={{ width: '100%', borderRadius: '8px' }} />
                  </Grid>
                  <Grid item xs={6}>
                    <ListItemText
                      primary={
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#3273CF' }}>
                          {item.nombre}
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Typography variant="body1" color="textSecondary">
                            Marca: {item.marca} | Talla: {item.talla} | Color: {item.color}
                          </Typography>
                          <Typography variant="body2" color="textPrimary" sx={{ mt: 1 }}>
                            Precio Original: <span style={{ textDecoration: 'line-through', color: 'red' }}>{formatCurrency(item.precio_original)}</span>
                          </Typography>
                          <Typography variant="body2" color="primary" sx={{ fontWeight: 'bold', mt: 1 }}>
                            Precio Oferta: {formatCurrency(item.precio_oferta)} x {item.quantity || 1}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                            Ahorro por Unidad: {formatCurrency(parseFloat(item.precio_original) - parseFloat(item.precio_oferta))}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                            Subtotal: {formatCurrency(item.precio_oferta * (item.quantity || 1))}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign: 'right' }}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => eliminarDelCarrito(item.id)}
                      sx={{ color: '#e74c3c' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider sx={{ my: 2 }} />
            </React.Fragment>
          ))}
        </List>

        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#3273CF' }}>
            Total a Pagar: {formatCurrency(calcularTotal())}
          </Typography>
          <Typography variant="h6" sx={{ color: '#2E3033', mt: 1 }}>
            Total Ahorrado: {formatCurrency(calcularAhorro())}
          </Typography>
        </Box>
      </Box>

      {/* Sección Derecha: Formulario de Pago */}
      <Box
        sx={{
          flex: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: 2,
          padding: 2,
          position: { md: 'sticky' },
          top: { md: 80 },
          alignSelf: 'flex-start',
        }}
      >
        <Typography
          variant="h5"
          sx={{ mb: 4, textAlign: 'center', fontWeight: 'bold', color: '#2E3033' }}
        >
          Información de Pago
        </Typography>
        <form>
          <TextField
            fullWidth
            label="Nombre Completo"
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Correo Electrónico"
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <FormControl component="fieldset" sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#3273CF' }}>
              Método de Pago
            </Typography>
            <RadioGroup name="metodoPago" defaultValue="webpay">
              <FormControlLabel value="webpay" control={<Radio />} label="WebPay" />
              <FormControlLabel value="efectivo" control={<Radio />} label="Efectivo" />
              <FormControlLabel value="transferencia" control={<Radio />} label="Transferencia Bancaria" />
            </RadioGroup>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ padding: '10px', fontSize: '16px', fontWeight: 'bold' }}
          >
            Ir a Pagar
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Carrito;
