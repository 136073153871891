import React from 'react';
import { Box, Typography, TextField, Button, Grid, IconButton, Paper } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './Contacto.css';

function Contacto() {
  return (
    <Box className="contacto-container">
      <Paper elevation={4} className="contacto-paper">
        <Typography variant="h3" className="contacto-title">
          Contacta con Nosotros
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#2E3033' }}>
                Envíanos tus consultas
              </Typography>
              <Typography variant="body1" className="contacto-description">
                Ya sea que tengas preguntas sobre un producto o un pedido, estamos aquí para ayudarte. Completa el formulario a continuación y nos pondremos en contacto contigo lo antes posible.
              </Typography>
            </Box>
            <form>
              <TextField fullWidth label="Nombre" variant="outlined" className="contacto-textfield" />
              <TextField fullWidth label="Email" variant="outlined" type="email" className="contacto-textfield" />
              <TextField fullWidth label="Mensaje" variant="outlined" multiline rows={4} className="contacto-textfield" />
              <Button variant="contained" fullWidth className="contacto-button">
                Enviar Mensaje
              </Button>
            </form>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#2E3033' }}>
                Encuéntranos
              </Typography>
              <Box className="contacto-map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.5806379937756!2d-70.56052468496836!3d-33.60451008073016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf2f79ef9f51%3A0xf67f1186ff38afeb!2sNonato%20Coo%20910%2C%20Puente%20Alto%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1614790018360!5m2!1ses!2scl"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  title="Ubicación de la oficina"
                ></iframe>
              </Box>
            </Box>

            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#2E3033' }}>
              Síguenos en nuestras redes sociales
            </Typography>
            <Box className="contacto-social-icons">
              <IconButton href="https://www.facebook.com/">
                <FacebookIcon fontSize="large" />
              </IconButton>
              <IconButton href="https://wa.me/">
                <WhatsAppIcon fontSize="large" />
              </IconButton>
              <IconButton href="https://www.instagram.com/">
                <InstagramIcon fontSize="large" />
              </IconButton>
              <IconButton href="https://www.linkedin.com/">
                <LinkedInIcon fontSize="large" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default Contacto;
