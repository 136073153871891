import React, { useContext } from 'react';
import { IconButton, Badge } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link } from 'react-router-dom';
import { CarritoContext } from '../context/CarritoContext';

const CartIcon = () => {
  const { carrito } = useContext(CarritoContext);

  // Asegúrate de que el carrito siempre sea un array
  const itemCount = carrito && Array.isArray(carrito)
    ? carrito.reduce((total, item) => total + (item.quantity || 0), 0)
    : 0;

  return (
    <IconButton component={Link} to="/carrito" sx={{ color: '#ffffff' }}>
      <Badge badgeContent={itemCount} color="secondary">
        <ShoppingCartIcon />
      </Badge>
    </IconButton>
  );
};

export default CartIcon;
